.processing__title,
.processing__title ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.processing__title li {
  list-style: square;
  margin-left: 1.75em;
}

.restocking-fee__container {
  margin-bottom: 1em;
}

/* ------------ Skus cards ------------- */

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card.card--sku {
  margin: 0 0.5em 1em;
  width: 100%;
  max-width: 500px;
}

.card.card--sku .card-content {
  min-height: 220px;
}

.card.card--sku label {
  margin: 0.4em 0 0;
}

.card.card--sku .is-danger {
  margin: 0.5em auto;
}
