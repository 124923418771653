html {
  overflow-y: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  margin: 1em 0;
}

.App {
  text-align: center;
}

.is-orange {
  background-color: #ffa546 !important;
  color: white !important;
}

a.is-orange {
  border: 1px solid #ffa546!important;
}

.staging-bgcolor {
  background-color: rgb(255, 200, 50) !important;
}

.staging-bgcolor .navbar-link,
.staging-bgcolor .navbar-item,
.staging-bgcolor .navbar-dropdown {
  background-color: rgb(255, 200, 50) !important;
}

.staging-bgcolor .navbar-link:hover,
.staging-bgcolor .navbar-item:hover {
  background-color: rgb(228, 179, 46) !important;
}

.navbar-item.active,
.navbar-item.active:focus {
  border-bottom: 3px solid #3273dc;
  background: #f5f5f5;
  font-weight: bold;
}

.navbar__logo {
  height: 62px;
  width: 95px;
}

@media screen and (max-width: 768px) {
  .navbar__logo {
    height: 52px;
    width: 77px;
  }
}

.navbar__title.title {
  margin-bottom: 0;
  padding-left: 0;
}

.navbar__lang-switch {
  margin-left: 0.5em;
}

.title span.navbar__title__version {
  color: rgb(76, 76, 76);
  font-weight: normal;
  font-size: 14px;
  position: relative;
  top: 1px;
}

/* -------------- Loading spinner ----------- */
.lds-spinner__container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-top: 2em;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #525252;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* ------------ Checkbox --------------- */

.checkbox-container {
  display: inline-block;
  height: 25px;
  position: relative;
  padding-left: 35px;
  margin: 0 auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #000;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ----------- Messages ------------- */

.message-dialog {
  margin: 0 auto;
  max-width: 350px;
}

.message-modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.message-modal-container .message {
  margin-top: 8%;
}

.message-modal-container .buttons {
  margin-top: 1em;
}

.message-body {
  max-height: 400px;
  overflow-y: auto;
}
/* ----------- Item selection --------------- */

.items__selection {
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 1em;
}

.items__selection.return-creating {
  height: 540px;
}

.items__selection.price-match {
  height: auto;
  max-height: 640px;
}

.items__selection form {
  margin-top: 0;
}

.items__selection ul {
  height: 340px;
  overflow: auto;
}

.items__selection ul.price-match {
  height: auto;
  max-height: 478px;
}

.items__selection__item {
  display: flex;
  align-items: center;
}

.items__selection__item-content {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.items__selection__item-content:hover {
  color: hsl(0, 0%, 71%);
}

.items__selection__item .unavailable {
  color: rgb(183, 183, 183);
}
