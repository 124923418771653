.return__table {
  width: 90%;
  max-width: 48em;
  margin: 3em auto 2em;
}

.return__table__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.return__table__cell {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}

.return__table__cell:last-child {
  border-right: none;
}

.return__table__row:last-child .return__table__cell {
  border-bottom: none;
}

.return__save-btn {
  margin-right: 0.5em;
}

.return__skus {
  margin-top: 1.5em;
}

.message.return__sku .message-header,
.message.return__sku .message-body {
  padding: 0.5em;
}

.message.return__sku .message-header div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.message.return__sku ul {
  display: flex;
  flex-wrap: wrap;
}

.message.return__sku li {
  display: flex;
  align-items: center;
  margin: 0.25em 0.5em;
  text-align: left;
  min-width: 16.5em;
}

.message.return__sku li select {
  margin-left: 0.5em;
}

.message.return__sku .has-text-link,
.return__sku__delete {
  cursor: pointer;
}

.message.return__sku .has-text-link:hover {
  color: rgb(74, 74, 74) !important;
}

.return__sku__delete {
  margin-left: 0.5em;
}

.return__sku__delete:hover {
  background: #c72a4a !important;
}

.return__add-sku {
  margin-left: auto;
}

.return__add-sku-modal {
  width: 90%;
  max-width: 65em;
  z-index: 100000;
}

.return__available-sku {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1em 0;
}

.return__available-sku__left {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.icon.add.return_add-sku {
  margin-right: 0.2em !important;
}

.return__available-sku__right {
  width: 20em;
}

.return__available-sku .input {
  margin-top: 0.5em;
}

.return__confirm-add-sku {
  margin-top: 1em;
}

.modal-content.return__return-label {
  max-width: 1280px;
  height: 90%;
  width: 90%;
}

.modal-content.return__return-label .box {
  height: 100%;
}

.modal-content.return__return-label iframe {
  height: 100%;
  width: 100%;
}

.return__add-sku-modal p {
  margin-bottom: 1em;
}

.return-exception button {
  margin: 1em 0.5em 0;
}

.return-exception__sku {
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 0.4em 0 0.2em;
  max-width: 35em;
  grid-gap: 0.75em;
  margin: 0 auto;
}

.label.return-exception__sku__title {
  margin-bottom: 1em;
}

.return-page__add-qr-code {
  margin-top: 0.75em;
}

.return__qr-code__img {
  height: 250px;
  width: 250px;
}

.return__buttons_group>* {
margin: 10px;
}

.return__pdf_link {
  margin-left: 20px;;
}

.return__failed_return_error_count {
  text-align: right;
  padding: 10px;
}

.return__failed_return_error_count p:nth-child(1) {
  font-size: 1.2rem;
  font-weight: 900;
}

.return__failed_return_error_count p:nth-child(2) {
  font-size: 0.6rem;
  font-weight: 500;
}
