.return-reports h2 {
  margin-top: 3.5em;
}

.return-reports h2:first-child {
  margin-top: 2.75em;
}

.reports__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reports__item {
  border: 1px solid #ccc;
  margin: 0.4em;
}

.reports__item__number {
  height: 175px;
  min-width: 300px;
  flex-grow: 1;
}

.reports__item__fullwidth {
  height: 175px;
  width: 100%;
}

.reports__item__graph {
  height: 400px;
  min-width: 550px;
  flex-grow: 1;
}

.reports__item__iframe {
  height: 100%;
  width: 100%;
}
