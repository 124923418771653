.is-flex.centered {
  align-items: center;
  justify-content: center;
}

.is-flex.aligned {
  align-items: center;
}

.is-flex.wrap {
  flex-wrap: wrap;
}

.is-flex.flow-col {
  flex-flow: column;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: inherit;
  cursor: pointer;
}

.btn-link:hover {
  color: #000 !important;
}

.is-size-65 {
  font-size: 0.875rem;
}

.has-background-orange {
  background-color: rgb(255, 126, 65);
}

.has-text-orange {
  color: rgb(255, 126, 65);
}
