.price-match__table {
  width: 90%;
  max-width: 48em;
  margin: 3em auto 2em;
}

.price-match__table__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.price-match__table__cell {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}

.price-match__table__cell:last-child {
  border-right: none;
}

.price-match__table__row:last-child .price-match__table__cell {
  border-bottom: none;
}

.price-match__skus {
  margin-top: 1.5em;
}

.price-match__sku {
  padding: 0.5em 1em;
  position: relative;
  text-align: left;
}

.price-match__sku ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}