.return-form {
  text-align: left;
}

.return-form__returned-items,
.return-form__notes,
.return-form__expected-refund {
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 1em;
}

.return-form__returned-items.single-return-page {
  height: auto;
}

.return-form__notes .field:first-child {
  margin-top: 0;
}

.return-form .button.is-dark.is-medium {
  margin-top: 0.5em;
}

.return-form__returned-items__item .message-header {
  padding: 0.5em;
}

.return-form__returned-items__item .message-body {
  padding: 0.5em 0.75em;
}

.return-form__returned-items__item-right {
  width: 100%;
  margin-left: 1em;
}

.return-form__returned-items__item-element {
  margin: 0.25em 0;
}

.return-form__returned-items__item-element span {
  margin-right: 0.5em;
}

.return-form__returned-items__item-element .checkbox-container {
  margin: 0.25em 1em 0.25em 0;
}

.return-form__exception__sku {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
}

.return-form__exception__sku input {
  margin-right: 0.3em;
}

.return-reception-note__form {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.return-reception-note__form .textarea {
  resize: none;
  height: 130px;
}

@media (min-width: 700px) {
  .return-form__enter-tracking {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  .return-form__enter-tracking .label {
    margin: 0 1em 0 0;
  }
}

.return-form__expected-refund__list {
  max-width: 600px;
}

.return-form__expected-refund__list-item {
  display: flex;
  justify-content: space-between;
}

.return-form__expected-refund__list-item-detail {
  margin-left: 4em;
}

.return-form__returned-items__item-element {
  text-align: left;
}

.return-form__action-btns {
  width: 75%;
  max-width: 12em;
}

.return-form__add-qr-code {
  margin: 0 0 0.75em;
}

.return-form__ship-to-als-toggle {
  margin: 0 0 0.75em;
}