.altidollars-refund__set-new {
  margin-top: 1.5em;
}

.altidollars-refund__deactivate {
  margin-right: 0.5em;
}

.altidollars-refund__set-new__modal .modal-content {
  min-height: 550px;
}

.altidollars-refund__set-new__modal .box {
  height: 100%;
}

.altidollars-refund__set-new__modal button {
  margin-top: 1.5em;
}

.altidollars-refund__set-new__modal .control {
  width: 15em;
  margin: 0 auto;
}

.altidollars-refund__set-new__modal p {
  margin-top: 1em;
}

.altidollars-refund__set-new__modal .is-size-7 {
  margin: 1em auto;
  max-width: 33em;
}