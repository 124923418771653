.objects-table__item__cell {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.objects-table__item__cell:last-child {
  border-right: none;
}

.objects-table__item:last-child .objects-table__item__cell {
  border-bottom: none;
}

.objects-table__item__cell.desktop {
  display: block;
}

@media screen and (max-width: 979px) {
  .objects-table__item__cell.desktop {
    display: none;
  }
}

.price-match__item {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 0.875rem;
  width: 100%;
}

.price-match__item:last-child .objects-table__item__cell {
  border-bottom: none;
}

@media screen and (max-width: 979px) {
  .price-match__item{
    grid-template-columns: repeat(4, 1fr);
  }

  .objects-table__item__cell:nth-child(5) {
    border-right: none;
  }
}

.returns-table__item {
  display: grid;
  grid-template-columns: 5% 10% 8% 12% 5% 9% 9% 9% 9% 9% 10% 5%;
  font-size: 0.875rem;
  width: 100%;
}

.altidollars-fixer-list__item {
  display: grid;
  grid-template-columns: 9% 32% 11% 8% 12% 12% 16%;
  font-size: 0.875rem;
  width: 100%;
}

.bulk-credits-table__item {
  display: grid;
  grid-template-columns: 19% 6% 8% 6% 7% 7% 8% 8% 8% 8% auto;
  font-size: 0.875rem;
  width: 100%;
}

.available-returns-table__item {
  display: grid;
  grid-template-columns: 10% 30% 30% 30%;
  font-size: 0.875rem;
  width: 100%;
}

.object-table_progressBar {
  width: 95%;
}

.objects-table__item__cell {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.objects-table__item__cell:last-child {
  border-right: none;
}

.returns-table__item:last-child .objects-table__item__cell {
  border-bottom: none;
}

.bulk-credits-table__item:last-child .objects-table__item__cell,
.altidollars-fixer-list__item:last-child .objects-table__item__cell {
  border-bottom: none;
}

@media screen and (max-width: 979px) {
  .objects-table__item__cell.desktop {
    display: none;
  }

  .objects-table__item__cell:nth-child(5) {
    border-right: none;
  }

  .bulk-credits-table__item .objects-table__item__cell:nth-child(7) {
    border-right: none;
  }

  .altidollars-fixer-list__item .objects-table__item__cell:nth-child(4) {
    border-right: none;
  }

  .returns-table__item,
  .bulk-credits-table__item,
  .altidollars-fixer-list__item {
    grid-template-columns: repeat(4, 1fr);
  }
}

.objects-table__topbar {
  margin-bottom: 2em;
}

.objects-table__topbar .tags.custom {
  margin-left: 1em;
}

.level.objects-table__topbar__top {
  margin-bottom: 0.5em;
}

.objects-table__sort-by {
  margin: 0 0 0 1em;
}

@media screen and (max-width: 768px) {
  .objects-table__topbar .tags.custom {
    margin-left: 0;
    justify-content: center;
  }

  .objects-table__sort-by {
    margin: 1em 0;
  }
}

.objects-table__filters {
  width: auto;
}

.objects-table__filters .box {
  margin: 1em;
  min-height: 500px;
}

.filters-tags .fa-times-circle {
  color: #9a9a9a;
  cursor: pointer;
  margin-left: 0.5em;
  position: relative;
}

.filters-tags .fa-times-circle:hover {
  color: #777;
}

.pagination button {
  background-color: transparent;
  cursor: pointer;
}

.objects-table__fails-warning .button {
  margin: 0.5em;
}

h1.capitalize_first:first-letter {
  text-transform: capitalize;
}
