.notification {
  margin: 2.5em auto 0;
}

@import './vendors/bulma.min.css';
@import './vendors/react-date-picker.css';
@import './elements.css';
@import './utilities.css';
@import './processing.css';
@import './return-form.css';
@import './return-page.css';
@import './return-reports.css';
@import './price-match-form.css';
@import './price-match-page.css';
@import './objects-table.css';
@import './marketing_tools.css';
@import './bulk-store-credits.css';
