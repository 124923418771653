.price-match-form__skus,
.price-match-form__client-info,
.price-match-form__note,
.price-match-form__past,
.price-match-form__expected-refund {
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 1em 0;
  min-height: 110px;
}

.price-match-form__client-info,
.price-match-form__note,
.price-match-form__past,
.price-match-form__expected-refund {
  margin-top: 1.5em;
  padding: 1em;
}

.price-match-form__expected-refund {
  text-align: left;
  min-height: 20px;
}

.price-match-form__note .textarea {
  min-height: 100px;
}

.price-match-form__client-info__content {
  width: 80%;
}

.price-match-form__skus__title {
  margin-left: 1em;
  text-align: left;
}

.price-match-form__sku__details {
  margin-top: 0.75em;
}

.price-match-form__skus__list {
  padding-right: 0.5em;
  margin-right: 0.5em;
}

.price-match-form__sku {
  margin-left: 1em;
}

.price-match-form__sku img {
  border: solid 3px white;
}

.price-match-form__sku .message-header {
  padding: 0.5em 0.75em 0.5em 0.5em;
}

.discount-code {
  align-items: center;
}

.discount-code .checkbox-container {
  margin: 0;
}

.price-match-form__sku__body-top,
.price-match-form__sku__body-bottom {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.price-match-form__sku__body-bottom {
  margin-top: 0.75em;
}

@media screen and (min-width: 1216px) {
  .price-match-form__sku__body-top {
    grid-template-columns: auto 15em;
  }
  .price-match-form__sku__body-bottom {
    grid-template-columns: 12em auto 15em;
  }
}

.price-match-form__sku a {
  text-decoration: none !important;
}

.price-match-form__sku a:hover {
  color: #d7d7d7 !important;
}

.price-match-form__price-tags {
  width: 100%;
}

.price-match-form__price-tag__span {
  cursor: pointer;
  margin: 0.3em;
}

.price-match-form__sku__price-input {
  max-width: 15em;
}

.price-match-form__submit {
  margin-left: 1em;
}

.credit-card-exception {
  margin: 1em;
}

.price-match-form__past__item {
  align-items: center;
  margin: 0.5em 0;
}

.price-match-form__past__item:last-child {
  align-items: center;
  margin-bottom: 0;
}

.price-match-form__past__icon {
  position: relative;
  top: 1px;
}

.price-match-form__past__item-info li {
  display: inline-block;
  margin: 0 0.5em 0 0;
}

.price-match-form__no-past-pm {
  font-weight: bold;
  margin: 1em 0 0 1em;
  text-align: left;
}
