.DayPickerInput > input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
  display: block;
  font-size: 1em;
  max-width: 100%;
  outline: 0;
  background-color: #fff;
  color: #363636;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid #dbdbdb;
  box-shadow: none;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  position: relative;
  width: 10em;
}

.datePickerFirst input {
  border-right: none;
}

.DayPickerInput > input:hover {
  border: 1px solid #b5b5b5;
}
